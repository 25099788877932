import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { LandingComponent } from './landing/landing.component';
// import { BlogComponent } from './blog/blog.component';
// import { PagesComponent } from './pages/pages.component';
// import { ElementsComponent } from './elements/elements.component';
// import { PortfolioComponent } from './portfolio/portfolio.component';
// import { FeaturesComponent } from './features/features.component'
// import { ShopComponent } from './shop/shop.component'
//import { PoultryComponent } from './layouts/poultry/poultry.component';
//import { AgencyNavComponent } from './layouts/agency/agency-nav/agency-nav.component';
//import { AgencyHeaderComponent } from './layouts/agency/agency-header/agency-header.component';
//import { AgencyContentComponent } from './layouts/agency/agency-content/agency-content.component';
import { PoultryAppComponent } from './layouts/poultry-app/poultry-app.component';
//import { NavService } from './shared/service/nav.service';
//import { AppPoultryComponent } from './layouts/app-poultry/app-poultry.component';
//import { ModernDemoComponent } from './layouts/modern/modern-demo/modern-demo.component';
//import { ModernComponent } from './layouts/modern/modern.component';
//import { AgencyServicesComponent } from './layouts/agency/agency-services/agency-services.component';
////import { AgencyPricingComponent } from './layouts/agency/agency-pricing/agency-pricing.component';
//import { AgencyTestimonialComponent } from './layouts/agency/agency-testimonial/agency-testimonial.component';
//import { AgencyFooterComponent } from './layouts/agency/agency-footer/agency-footer.component';
//import { PoultryComponent } from './layouts/poultry/poultry.component';
//import { AgencyComponent } from './layouts/agency/agency.component';
//import { AgencyContentComponent } from './layouts/agency/agency-content/agency-content.component';
export const routes: Routes = [


  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',

  },
  {
    path: '',
    component: PoultryAppComponent
  },
  // {
  //   path: 'index',

  // },
  // {
  //   path: 'about',


  // },
//   {
//     path: 'index',
//     component: AppPoultryComponent
// },
  // {
  //     path: 'about',
  //     component: AppPoultryComponent
  // },
  // {
  //   path:   'fetures',
  //   component: AppPoultryComponent
  // },
  //  {
  //   path:   'modules',
  //   component: AppPoultryComponent
  // },
  // {
  //   path:   'clients',
  //   component: AppPoultryComponent
  // },
  // {
  //   path:   'pricing',
  //   component: AppPoultryComponent

  // },
  // {
  //   path:   'addition',
  //   component: AppPoultryComponent

  // },
  //  {
  //   path:   'demo',
  //   component: AppPoultryComponent
  // },
  // {
  //   path:   'contact',
  //   component: AppPoultryComponent
  // }
];




// {
//   path: '',
//   redirectTo: 'Home',
//   pathMatch: 'full',

// },
// {
//   path: 'Home',
//   component: PoultryComponent
// },
// {
//   path: 'contact',
//   component: PoultryComponent
// },
// {
//   path: 'blog',
//   component: BlogComponent,
//   loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
//   data: {
//     breadcrumb: "Blog"
//   }
// },
// {
//   path: 'page',
//   component: PagesComponent,
//   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
//   data: {
//     breadcrumb: "Pages"
//   }
// },
// {
//   path: 'elements',
//   component: ElementsComponent,
//   loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule),
//   data: {
//     breadcrumb: "Elements"
//   }
// },
// {
//   path: 'portfolio',
//   component: PortfolioComponent,
//   loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule),
//   data: {
//     breadcrumb: "Portfolio"
//   }
// },
// {
//   path: 'shop',
//   component: ShopComponent,
//   loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
//   data: {
//     breadcrumb: "Shop"
//   }
// },
// {
//   path: 'features',
//   component: FeaturesComponent,
//   loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
//   data: {
//     breadcrumb: "Features"
//   }
// }

// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
