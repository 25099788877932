export class blogClassicDB {
    static column = [
        {
            Id: 1,
            img: 'assets/images/agency/blog/13.jpg',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 2,
            img: 'assets/images/agency/blog/14.jpg',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 3,
            img: 'assets/images/agency/blog/15.jpg',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 4,
            img: 'assets/images/agency/blog/16.jpg',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        }
    ]
}