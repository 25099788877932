export class blogBasicDB {
  static column = [
    {
      Id: 1,
      img: "assets/images/app_landing2/service/poultry.jpg",
      imgInner: "assets/images/app_landing2/service/poultry.jpg",
      date: "June 19, 2018",
      type: "Admin",
      title: "POULTRY MANAGEMENT PORTAL",
      titleInner: "WHAT IS POULTRY MANAGEMENT PORTAL FOR BIZSOFT SOLUTIONS?",
      content:
        "In this modern world, modern solution requires for a business. Bizsoft Solutions (Pvt.) Limited provide the solution and allow to increase poultry productivity Plan, manage and report on poultry operation. Gain insight into flocks. Our flexible features let’s you manage your poultry in your way.",
      contentInnerOne:
        "In this modern world, modern solution requires for a business. Bizsoft Solutions (Pvt.) Limited provide the solution and allow to increase poultry productivity Plan, manage and report on poultry operation. Gain insight into flocks. Our flexible features let’s you manage your poultry in your way",
      contentInnerTwo:
        "Keeping accurate records of your flocks is imperative to help measure and track your production.",
      contentInnerThree:
        "Need to understand Poultry Management Software Market Trends more than ever. Bizsoft will help you get a real picture of the industry landscape. So that you can determine the direction it is heading in.",
      contentInnerFour:
        "Expanding and growing an already-existing poultry farm would be even more demanding.",
      contentInnerFive:
        "Most importantly, a poultry management software must be user-friendly enough for the company’s employees to be comfortable in using it. Employees who may be used to certain reporting formats and methods may initially be reluctant to embrace an ERP for poultry business",
      contentListOne: "Mortality Tracking",
      contentListTwo: "Daily Consumption",
      contentListThree: "Feed Planning",
      contentListFour: "KPI Monitoring",
      btn: "read more",
    },
    {
      Id: 2,
      img: "assets/images/app_landing2/service/poultry-blog2.jpg",
      imgInner: "assets/images/app_landing2/service/poultry-blog2.jpg",
      date: "June 19, 2018",
      type: "Bizsoft",
      title: "POULTRY BROILER SOFTWARE",
      titleInner:
        "OPTIMIZING BROILER MANAGEMENT: THE ROLE OF POULTRY ERP SYSTEM",
      content:
        "A well-run poultry broiler farm can be an incredibly rewarding venture. With the help of modern poultry broiler software, farm management has become easier and more efficient. We'll delve into the basics of poultry broiler management, from chick selection to chicken sale, and everything in between.",
      contentInnerOne:
        "Poultry broiler management encompasses various factors like feed and vaccine consumption, managing stock levels, and monitoring mortality rates. These elements can be efficiently managed with a comprehensive poultry management system or poultry ERP system. Leveraging such a system not only streamlines operations but also ensures optimal growth conditions for the broilers, ultimately leading to higher yields and profitability. From selecting high-quality chicks to their final sale, a reliable poultry software can significantly simplify the entire broiler management process.",
      contentInnerTwo:
        "Optimize farm infrastructure using ERP software for streamlined operations. Ensure broiler comfort with ample space, ventilation, and temperature control. Implement regular sanitization schedules to promote efficient broiler growth.",
      contentInnerThree:
        "Poultry broiler software streamlines flock management, including purchases and consumption tracking. It optimizes sales and generates insightful reports for data-driven decisions, enhancing farm efficiency and profitability. Leveraging technology keeps farmers competitive in the evolving poultry industry.",
      contentInnerFour:
        "Poultry ERP software offers a range of features and functionalities to effectively manage day-to-day tasks and data associated with broiler farming. Here are some ways in which poultry ERP software can contribute to the establishment and control of a broiler farm:",
      contentInnerFive:
        "By using poultry ERP software, broiler farmers can increase operational efficiency, enhance productivity, and ensure consistent quality. It provides a centralized platform for managing all aspects of broiler farming, saving time and effort while enabling better control and informed decision-making. Investing in a reliable and user-friendly poultry ERP software can be a valuable addition to any broiler farm, ultimately leading to improved profitability and sustainable growth in the poultry industry.",
      contentListOne: "Inventory Management",
      contentListTwo: "Production Planning",
      contentListThree: "Financial Management",
      contentListFour: "Data Analysis and Reporting",
      contentListFive: "Compliance and Traceability",
      btn: "read more",
    },
    {
      Id: 1,
      img: "assets/images/app_landing2/service/poultry-blog.jpg",
      imgInner: "assets/images/app_landing2/service/poultry-blog.jpg",
      date: "June 19, 2018",
      type: "Bizsoft",
      title: "POULTRY LAYER SOFTWARE",
      titleInner: "POULTRY LAYER SOFTWARE: A KEY TO EFFICIENT FARM MANAGEMENT",
      content:
        "In today's world, efficient poultry management is a necessity, not a luxury. Poultry layer software offers significant benefits, managing the life cycle of layers and enhancing farm efficiency. It tracks egg production and sales, making farm operations more effective.",
      contentInnerOne:
        "Implementing poultry layer software revolutionizes poultry management, eliminating manual labor in tasks like Layer To Egg Tracking and chicks procurement. Its automation ensures prompt, accurate data for informed decision-making, boosting productivity and reducing costs.",
      contentInnerTwo:
        "Revolutionizing poultry farm management, our software automates key processes, tracking egg production stages and improving productivity. From chick procurement to brooding transactions, it ensures a steady supply and accurate data, eliminating manual errors.",
      contentInnerThree:
        "Basics of layer management: Create suitable environment, provide balanced nutrition, and prioritize disease prevention. Effective record-keeping and data analysis are essential for maximizing efficiency and productivity in poultry farming.",
      contentInnerFour:
        "Poultry layer software streamlines management by tracking layer progress, managing egg collection and sales, and facilitating egg sorting.",
      contentInnerFive:
        "It offers insights into flock performance, aiding in productivity enhancement. Cost Amortization feature ensures effective budget planning, making it an invaluable asset for modern poultry management.",
      contentListOne: "Layer Life Cycle Tracking",
      contentListTwo: "Egg Collection and Sales Management",
      contentListThree: "Flock Performance Analysis",
      contentListFour: "Egg Bifurcation",
      contentListFive: "Cost Amortization",
      btn: "read more",
    },
  ];
}
