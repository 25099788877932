<header class="app2 header-fixed loding-header position-fixed custom-scroll nav-lg"
  [ngStyle]="{ 'background-color': 'rgb(255 79 0 / 80%)' }">
  <div class="animated-bg"><i></i><i></i><i></i></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <nav>
          <a href="https://poultry.live/" class="d-inline-block m-r-auto">
            <img src="assets/images/logo/bizsoft-logo.png" alt="" class="img-fluid" />
          </a>
          <div class="mobile-menu m-x-auto" id="nav">
            <nav id="navbar-example2" class="navbar navbar-expand-lg navbar-light">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#scroll-spy"
                aria-controls="scroll-spy" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="scroll-spy">
                <ul class="navbar-nav mx-auto nav">
                  <li class="nav-item">
                    <a class="nav-link active" #index (click)="active('index')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'index' }">POULTRY ERP</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('about')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'about' }">ABOUT</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('fetures')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'fetures' }">FEATURE</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('modules')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'modules' }">MODULES</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('clients')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'clients' }">CLIENTS</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('addition')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'addition' }">ADDITION</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('workflow')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'workflow' }">WORKFLOW</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('blog')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'blog' }">BLOGS</a>
                  </li>
                  <!-- <li class="nav-item">
                    <a class="nav-link" (click)="active('pricing')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'pricing' }">PRICING</a>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('demo')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'demo' }">DEMO</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="active('contact')" id="navcolour"
                      [ngClass]="{ 'active show': activeClass == 'contact' }">CONTACT</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <app-menu> </app-menu>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Nav end -------------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- header start --------------------------------------------------------------------------- -->
<div class id="index">
  <section class="app2 header overflow-unset">
    <div class="app2-header bg">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-md-6">
            <div class="center-text">
              <div>
                <h6 class="header-top-line">@ Poultry The Digital World</h6>
                <div class="header-text">
                  <h1 style="letter-spacing: normal; color: white">
                    the best
                    <span class="bold-text">Poultry Software Solutions</span>
                    for your Business
                  </h1>
                </div>
                <div class="header-sub-text">
                  <p class="text-white">
                    POULTRY ERP FOR DIGITISED POULTRY OPERATIONS. GET STARTED IN
                    LESS THAN 1 WEEKS !
                  </p>
                </div>
                <div class="link-horizontal">
                  <ul>
                    <li>
                      <a class="nav-link" (click)="active('demo')" class="btn btn-default btn-white">get Quotation</a>
                    </li>
                    <li>
                      <a href="assets/downloads/poultry-software.pdf" target="_blank"
                        class="btn btn-default primary-btn transparent">Download Brochure</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-md-6">
            <div class="img-mobile set-abs">
              <img src="assets/images/app_landing2/h2-mobile.png" alt="poultry farming" class="headaer-image" />
            </div>
            <div class="wave-orange"></div>
          </div>
        </div>
      </div>
      <div class="wave"></div>
    </div>
  </section>
</div>
<!--header end ------------------------------------------------------------------------------ -->
<!-- ======================================================================================== -->
<!--about start ----------------------------------------------------------------------------- -->
<div class id="about">
  <section class="app2 about format">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
      <div class="row">
        <div class="col-sm-5 counters set-height">
          <img src="assets/images/app_landing2/mobile.png" alt="image icon" class="img-fluid mobile1" />
          <img src="assets/images/app_landing2/j.png" alt="about image " class="img-fluid j-img" />
        </div>
        <div class="col-sm-7 counters">
          <div class="abouts center-text">
            <div>
              <div class="format-small-text">
                <h6 class="font-primary borders-before text-uppercase">
                  <span>Why You Choose Us</span>
                </h6>
              </div>
              <div class="format-head-text">
                <h2 class="about-font-header font-secondary" style="
                    color: rgb(0, 0, 0);
                    font-family: 'Courier New', Courier, monospace;
                  ">
                  PROJECT OVERVIEW
                </h2>
              </div>
              <div class="format-sub-text">

                <div class="about-para">
                  <h2 class="about-para-heading">
                    Poultry Software Solutions Is an ERP for the digital
                    transformation of your Poultry form helps you keep your
                    business on track and managing different but integrated
                    poultry
                  </h2>operations with multi location farms. Now you can plan
                  your Flock Placements, Procurement and Cumulative Feed or
                  Medicine Production and you can also Control Loss of Yield,
                  Daily Mortality, Weight, Feed Requests and Consumption,
                  Medicine Requests and Consumption, Inconsistent KPIs, High
                  Expenses and no Preventive Actions. Give access to information
                  to all stakeholders from supervisor manager doctor farmer and,
                  management with instant, alerts notifications. And reminders
                  for activities.
                  <h2 class="about-para-heading">
                    Poultry Management Portal also has out of the
                    box integrations with leading ERP products..
                  </h2>
                </div>
              </div>
              <ul class="icon-collection">
                <li class="about-icon">
                  <a href="https://bizsoft.pk/poultry.html" target="_blank" class="center-content"><img
                      src="assets/images/app_landing2/about-icons/1.png" alt="icon" class="img-fluid" /></a>
                </li>
                <li class="about-icon">
                  <a href="https://bizsoft.pk/poultry.html" target="_blank" class="center-content"><img
                      src="assets/images/app_landing2/about-icons/2.png" target="_blank" alt="icon"
                      class="img-fluid" /></a>
                </li>
                <li class="about-icon">
                  <a href="https://bizsoft.pk/poultry.html" class="center-content"><img
                      src="assets/images/app_landing2/about-icons/3.png" alt="icon" class="img-fluid" /></a>
                </li>
              </ul>
              <a href="https://bizsoft.pk/poultry.html" target="_blank"
                class="btn btn-default btn-gradient m-t-50">learn more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- about end ------------------------------------------------------------------------------ -->
<!-- ======================================================================================== -->
<!-- services start ------------------------------------------------------------------------- -->
<div class id="fetures">
  <section class="app2 services p-t-0">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
            <h6 class="font-primary borders main-text text-uppercase">
              <span>Product Features</span>
            </h6>
            <div class="sub-title">
              <h1 class="" style="
                  color: rgb(0, 0, 0);
                  font-family: 'Courier New', Courier, monospace;
                ">
                POULTRY SOFTWARE FEATURES
              </h1>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of services">
          <div class="service text-center">
            <div class="img-block">
              <img [src]="data.img" alt="feature img" class="service-img img-fluid" />
            </div>
            <div class="service-feature">
              <h4 class="feature-text text-center">{{ data.title }}</h4>
              <div class="container5">
                <div class="accordion">
                  <p class="accordion-toggle">{{ data.description1 }}</p>
                  <div class="accordion-content">
                    <div class="accordion-inner">
                      <p class="feature-para" style="color: #aaaaaa">
                        {{ data.description2 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<style>
  .container5 {
    width: 340px;
  }

  .accordion-content {
    transition: max-height 1s;
    overflow: hidden;
    max-height: 0;
    padding-left: 10px;
    text-align: start;
  }

  .accordion-inner {
    padding: 10px;
  }

  .accordion-toggle {
    transition: background 0.1s linear;
    border-radius: 3px;
    color: #aaaaaa;
    display: block;
    font-size: 15px;
    text-align: start;
    text-decoration: none;
    padding-left: 20px;
  }

  .accordion:hover .accordion-content {
    max-height: 300px;
    padding-left: 10px;
    text-align: start;
    color: #aaaaaa;
  }

  .accordion-toggle:hover {
    background-color: darken(#fff);
    color: #aaaaaa;
  }

  @media only screen and (max-width: 575px) {
    .container5 {
      width: 300px;
    }

    .accordion-content {
      transition: max-height 1s;
      overflow: hidden;
      padding-left: 7px;
      text-align: center;
    }

    .accordion-inner {
      padding: 7px;
    }

    .accordion-toggle {
      font-size: 14px;
      text-align: center;
      padding-left: 15px;
    }

    .accordion:hover .accordion-content {
      max-height: 250px;
      padding-left: 7px;
      text-align: center;
    }
  }
</style>
<!-- services end --------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- modules start -------------------------------------------------------------------------- -->
<div class id="modules">
  <section class="app2 team p-t-0">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
            <h6 class="font-primary borders main-text">
              <span>PRODUCT MODULES</span>
            </h6>
            <div class="sub-title">
              <h1 style="
                  color: rgb(0, 0, 0);
                  font-family: 'Courier New', Courier, monospace;
                ">
                POULTRY SOFTWARE MODULES
              </h1>
            </div>
          </div>
        </div>
        <div class="col-12">
          <owl-carousel-o [options]="teamcarouselOptions" class="team-slider">
            <ng-container *ngFor="let team of teams">
              <ng-template carouselSlide class="item">
                <div class="team-container">
                  <img [src]="team.img" alt="modules icon image" class="img-fluid productforbusiness" />
                  <div class="text-center">
                    <h3 class="name">{{ team.name }}</h3>
                    <h6 class="post">{{ team.designation }}</h6>
                    <p class="team-para">{{ team.description }}</p>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- modules end ---------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- screen-shot start ---------------------------------------------------------------------- -->
<div class id="clients">
  <section class="app2 screenshot p-t-0" id="screen-shot">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
            <h6 class="font-primary borders main-text">
              <span>PRODUCT CLIENTS</span>
            </h6>
            <div class="sub-title">
              <h1 style="
                  color: rgb(0, 0, 0);
                  font-family: 'Courier New', Courier, monospace;
                ">
                POULTRY SOFTWARE CLIENTS
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row set-relative">
        <div class="col-12">
          <owl-carousel-o [options]="screenshotscarouselOptions" class="screenshot-slider">
            <ng-container *ngFor="let screenshot of screenshots">
              <ng-template carouselSlide class="item">
                <img [src]="screenshot.img" alt="clients" class="img-fluid" />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
</div>
<!---screen-shot end ------------------------------------------------------------------------ -->
<!-- ======================================================================================== -->
<!-- team start ----------------------------------------------------------------------------- -->
<div class id="addition">
  <section class="app2 team-section p-t-0">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
            <h6 class="font-primary borders main-text">
              <span>PRODUCT VALUE ADDITIONS</span>
            </h6>
            <div class="sub-title">
              <h1 style="
                  color: rgb(0, 0, 0);
                  font-family: 'Courier New', Courier, monospace;
                ">
                POULTRY SOFTWARE VALUE ADDITIONS
              </h1>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 text-center teams" *ngFor="let data of users">
          <div class="our-team">
            <div class="set-relative">
              <div class="border-around overflow-hide m-auto">
                <div class="set-relative overflow-hide">
                  <img [src]="data.img" alt="" class="img-fluid team" />
                  <div class="overlay-team set-abs">
                    <img src="assets/images/app_landing2/team/hover-wave.png" alt="" class="img-fluid wave" />
                  </div>
                </div>
              </div>
            </div>
            <div class="set-relative set-border">
              <div class="set-abs top-0 borders"></div>
            </div>
            <div class="employee">
              <h4 class="e-name text-center">{{ data.name }}</h4>
              <h6 class="post text-center">{{ data.designation }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- team end ------------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- work section --------------------------------------------------------------------------- -->
<div id="workflow">
  <section id="how-work" class="saas1 howitwork"
    [ngStyle]="{'background-image': 'url(assets/images/saas1/work-bg.png)'}">
    <div class="container">
      <div class="row">

        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img"
              style="width: 50px;" />
            <h6 class="font-primary borders main-text">
              <span>Workflow</span>
            </h6>
            <div class="sub-title">
              <h1 style="
                color: rgb(0, 0, 0);
                font-family: 'Courier New', Courier, monospace;
                font-size: 40px;
                font-weight: 100;">
                POULTRY SOFTWARE WORKFLOW
              </h1>
            </div>
          </div>
        </div>
        <div class="col-md-12 work-tab">
          <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
            <li class="nav-item  text-center" *ngFor="let data of nav">
              <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }"
                (click)="openWorkSlide(data.title)">
                <img [src]="data.img" alt="tab-image-1" style="width: 100px;">
                <h6>{{data.title}}</h6>
                <span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="work-tab-bg work-content p-t-50">
      <div class="tab-content text-center" id="pills-tabContent">
        <div class="tab-pane fade show active" *ngFor="let data of content"
          [ngClass]="{'active show': work == data.title }" id="pills-work1" role="tabpanel">
          <img [src]="data.img" alt="tab-image-1" class="img-fluid" style="width: 635px;">
        </div>
      </div>
    </div>
  </section>
</div>
<!-- end work section ----------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- price start ---------------------------------------------------------------------------- -->
<!-- <div class id="pricing">
  <section class="app2 pricing p-t-0" id="PRICING">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
            <h6 class="font-primary borders main-text">
              <span>PRODUCT PRICING</span>
            </h6>
            <div class="sub-title">
              <h2 class="title-text text-capitalize text-center" style="font-family: 'Courier New', Courier, monospace">
                POULTRY SOFTWARE PRICING
              </h2>
            </div>
          </div>
        </div>
        <div class="col-12">
          <owl-carousel-o [options]="pricingcarouselOptions" class="pricing-slider price-margin">
            <ng-container *ngFor="let price of pricing">
              <ng-template carouselSlide class="item">
                <div class="price-container shadows text-center">
                  <div class="service-feature">
                    <div class="feature-text">
                      <span class="flaticon-{{ price.icon }} feature-icon"></span>
                      <h3 class="text-white feature-text-heading text-center">
                        {{ price.package }}
                      </h3>
                    </div>
                  </div>
                  <div>
                    <div class="price-feature-container">
                      <div class="price-features">
                        <h5 class="price-feature text-center">
                          {{ price.feature1 }}
                        </h5>
                        <h5 class="price-feature text-center">
                          {{ price.feature2 }}
                        </h5>
                        <h5 class="price-feature text-center">
                          {{ price.feature3 }}
                        </h5>
                        <h5 class="price-feature text-center">
                          {{ price.feature4 }}
                        </h5>
                        <h5 class="price-feature text-center">
                          {{ price.feature5 }}
                        </h5>
                        <h5 class="price-feature text-center">
                          {{ price.feature6 }}
                        </h5>
                      </div>
                      <div class="price-value">
                        <h6 class="price text-center">
                          <span class="large">{{ price.price }}</span>/One Time
                        </h6>
                      </div>
                      <div class="price-value">
                        <h6 class="price text-center">
                          Rs:<span class="large">{{ price.price2 }}</span>/month
                        </h6>
                      </div>
                      <a class="nav-link" (click)="active('demo')" class="btn btn-default btn-white">{{ price.btn }}</a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
</div> -->
<!-- price end ------------------------------------------------------------------------------ -->
<!-- ======================================================================================== -->
<!-- blogs start ---------------------------------------------------------------------------  -->
<div id="blog">
  <section class="agency blog blog-sec">
    <div class="container no-side">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title title2">
            <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img"
              style="width: 50px;" />
            <h6 class="font-primary borders main-text">
              <span>PRODUCT BLOGS</span>
            </h6>
            <div class="sub-title">
              <h1 style="color: rgb(0, 0, 0); font-family: 'Courier New', Courier, monospace;">
                POULTRY SOFTWARE BOLGS
              </h1>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6" *ngFor="let blog of blogData">
          <div class="blog-agency">
            <div class="blog-contain">
              <img [src]="blog.img" alt="" class="img-fluid">
              <div class="img-container center-content">
                <div class="center-content">
                  <div class="blog-info">
                    <div class="m-b-20">
                      <div class="center-text">
                        <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                        <h6 class="m-r-25 font-blog">{{blog.date}}</h6>
                        <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                        <h6 class="font-blog">{{blog.type}}</h6>
                      </div>
                    </div>
                    <h5 class="blog-head font-600">{{blog.title}}</h5>
                    <p class="para2">{{blog.content}}</p>
                    <div class="btn-bottom m-t-20">
                      <!-- <a href="#" class="text-uppercase">{{blog.btn}}</a> -->
                      <!-- Popup start  -->
                      <div class="col-md-6 offset-md-3">
                        <div class="text-center">
                          <a class="btn btn-default btn-gradient m-t-50"
                            (click)="openVerticallyCentered(content)">{{blog.btn}}</a>
                          <ng-template #content let-modal>
                            <div class="login-modal">
                              <div class="modal-header">
                                <button aria-label="Close" (click)="modal.dismiss('Cross click')" class="close"
                                  data-dismiss="modal" type="button">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body text-center">
                                <h3 class="m-b-20"> {{blog.titleInner}}</h3>
                                <img [src]="blog.imgInner" alt="poultry Blogs" class="img-fluid blog-images">
                                <p class="contentInnerOne">{{blog.contentInnerOne}}</p><br>
                                <p class="contentInnerTwo">"{{blog.contentInnerTwo}}"</p><br>
                                <p class="contentInnerThree">{{blog.contentInnerThree}}</p><br>
                                <p class="contentInnerFour">{{blog.contentInnerFour}}
                                  <li class="contentList">{{blog.contentListOne}}</li>
                                  <li class="contentList">{{blog.contentListTwo}}</li>
                                  <li class="contentList">{{blog.contentListThree}}</li>
                                  <li class="contentList">{{blog.contentListFour}}</li>
                                  <li class="contentList">{{blog.contentListFive}}</li>
                                  {{blog.contentInnerFive}}
                                </p>
                                <a aria-label="Close" class="btn btn-default primary-btn m-t-20"
                                  data-dismiss="modal">close
                                </a>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <!-- popup end  -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- blogs end ------------------------------------------------------------------------------ -->
<!-- ======================================================================================== -->
<!-- demo start ----------------------------------------------------------------------------- -->
<div class id="demo">
  <section class="app2 about format" id="DEMO">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
      <div class="col-md-10 offset-md-1">
        <div class="title title2">
          <img src="assets/images/logo/2.png" alt="poultry software" class="img-fluid title-img" />
          <h6 class="font-primary borders main-text text-uppercase">
            <span> Demo & Proposals</span>
          </h6>
          <div class="sub-title">
            <h2 class="title-text text-capitalize text-center"
              style="font-family: 'Courier New', Courier, monospace; color: black;">
              DEMO REQUEST
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 counters set-height">
          <img src="assets/images/app_landing2/mobile-02.png" alt="image icon" class="img-fluid demoimg" />
          <img src="assets/images/app_landing2/demo-request.png" alt="image icon" class="img-fluid demomovingimg" />
        </div>
        <div class="col-sm-7 counters">
          <div class="abouts center-text">
            <div>
              <div class="row">
                <div class="typography-box">
                  <div class="typo-content">
                    <form [formGroup]="myForm">
                      <div class="form-row">
                        <div class="col-12 mb-2">
                          <input type="radio" name="RequestType" id="1" value="Proposal Quotation"
                            formControlName="requestType" formControlName="requestType" />
                          <label for="one">&nbsp;Proposal Quotation</label>
                        </div>
                        <div class="col-12 mb-2">
                          <input type="radio" name="RequestType" id="2" value="Demo request"
                            formControlName="requestType" formControlName="requestType" />
                          <label for="two">&nbsp;Demo request</label>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-12 mb-2">
                          <input type="text" class="form-control" name="requestByName" id="requestByName"
                            formControlName="requestByName" placeholder="Enter Your name" required="true" type="text" />
                        </div>
                        <div class="col-12 mb-2">
                          <input type="text" class="form-control" name="companyName" placeholder="Enter Company name"
                            required="true" type="text" formControlName="companyName" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-12 mb-2">
                          <input type="number" class="form-control" name="mobile" placeholder="Enter Mobile number"
                            required="true" type="number" formControlName="mobile" />
                        </div>
                        <div class="col-12 mb-2">
                          <input type="text" class="form-control" name="email" placeholder="Your Email" required="true"
                            type="email" formControlName="email" />
                        </div>
                        <div class="col-12 mb-2">
                          <textarea class="form-control" placeholder="Write Your Message" name="message" rows="5"
                            type="text" formControlName="message"></textarea>
                        </div>
                      </div>
                      <div class="row"></div>
                    </form>
                  </div>
                </div>
              </div>
              <button class="btn btn-default btn-gradient m-t-50" (click)="submit()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- Demo end ------------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- footer start --------------------------------------------------------------------------- -->
<div class id="contact">
  <section class="app2 format quality p-t-0">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
      <div class="row">
        <div class="col-sm-6 counters set-order-2">
          <div class="abouts center-text">
            <div>
              <div class="format-head-text">
                <h2 class="about-font-header1 font-secondary" style="font-family: 'Courier New', Courier, monospace">
                  CONTACT US!
                </h2>
              </div>
              <div class="format-sub-text">
                <p class="about-para">
                  Anum Blessing, Suit No. 723, 7th Floor, Plot Zee KCHS Block 7
                  & 8 Opposite Old Duty Free Shop, Main Shahrah-e-faisal,
                  Karachi, Pakistan.
                </p>
              </div>
              <ul class="icon-collection">
                <li class="about-icon">
                  <a href="mailto:info@bizsoft.pk" target="_blank" class="center-content">
                    <h4 class="quality">Email</h4>
                    <h6 class="users">info@bizsoft.pk</h6>
                  </a>
                </li>
                <li class="about-icon">
                  <a href="https://api.whatsapp.com/send?phone=923002148149" target="_blank" class="center-content">
                    <h4 class="quality">WhatsApp</h4>
                    <h6 class="users">+92-301-8222487</h6>
                  </a>
                </li>
              </ul>
              <a href="https://goo.gl/maps/PfVjg64VJu5NN8YS8" target="_blank"
                class="btn btn-default btn-gradient m-t-45">Our Location Map</a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 counters set-height overflow-hide" [@fadeInRight]="fadeInRight">
          <a href="https://goo.gl/maps/PfVjg64VJu5NN8YS8" target="_blank">
            <img src="assets/images/app_landing2/l-2.png" alt="location map" class="img-fluid mobile2"
              data-aos-duration="1500" />
          </a>
        </div>
      </div>
    </div>
  </section>

  <footer class="app2 bg footer2 p-0" [ngStyle]="{
      'background-image': 'url(assets/images/app_landing2/l-2-footer.png)',
      'background-repeat': 'no-repeat',
      'background-position': 'top'
    }">
    <div class="subscribe subscribe-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div class="subscribe">
              <div class="center-content">
                <div class="form-group m-0">
                  <div class="flex">
                    <i aria-hidden="true" class="fa fa-envelope icon"></i>
                    <input class="form-control text-capitalize" name="email" placeholder="info@bizsoft.pk"
                      type="email" />
                    <div class="button-primary">
                      <button class="btn btn-default btn-gradient text-white text-capitalize" type="submit">
                        subscribe now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="logo-sec">
              <div class="footer-title mobile-title p-t-0">
                <h3 class="text-white">About Us</h3>
              </div>
              <div class="footer-contant">
                <img alt="logo" class="img-fluid footer-logo" src="assets/images/logo/bizsoft-logo.png" />
                <div class="footer-para">
                  <p class="text-white">
                    BIZSOFT IS AN INDEX GREAT LEVELER AS A SOFTWARE HOUSE IN
                    PAKISTAN.
                  </p>
                </div>
                <ul class="d-flex footer-social social">
                  <li class="footer-social-list">
                    <a href="https://www.facebook.com/bizsoftsolutions" target="_blank"><i aria-hidden="true"
                        class="fa fa-facebook"></i></a>
                  </li>
                  <li class="footer-social-list">
                    <a href="https://twitter.com/PvtBizsoft" target="_blank"><i aria-hidden="true"
                        class="fa fa-twitter"></i></a>
                  </li>
                  <li class="footer-social-list">
                    <a href="https://youtu.be/a0tKAvNQ0O4?si=BQHb3UkwKotRLvSY" target="_blank"><i aria-hidden="true"
                        class="fa fa-youtube"></i></a>
                  </li>
                  <li class="footer-social-list">
                    <a href="https://pk.linkedin.com/company/bizsoft-solutions-pvt-limited" target="_blank"><i
                        aria-hidden="true" class="fa fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Post Tags</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">Post Tags</h5>
              <div class="link-btns">
                <ul>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Poultry</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Layer</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Broiler</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Medicine</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Feeds</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Accounting</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#ERP</a>
                  </li>
                  <li class="buttons">
                    <a href="javascript:void(0)">#Livestock</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Helpful Links</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">Helpful Links</h5>
              <div>
                <ul class="footer-lists">
                  <li>
                    <a href="https://bizsoft.pk/" target="_blank">Bizsoft Solutions Private Limited</a>
                  </li>
                  <li>
                    <a href="https://bizsoft.pk/about.html" target="_blank">About</a>
                  </li>
                  <li>
                    <a href="https://bizsoft.pk/poultry.html" target="_blank">Poultry</a>
                  </li>
                  <li>
                    <a href="https://bizsoft.pk/solutions.html" target="_blank">Solutions</a>
                  </li>
                  <li>
                    <a href="https://bizsoft.pk/contact.html" target="_blank">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Contact Info</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">Contact Info</h5>
              <div>
                <ul class="footer-lists contacts">
                  <li>
                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                    Anum Blessing, Suit No. 723, 7th Floor, Plot Zee KCHS Block
                    7 & 8 Opposite Old Duty Free Shop, Main Shahrah-e-faisal,
                    Karachi, Pakistan.
                  </li>
                  <li>
                    <i aria-hidden="true" class="fa fa-phone m-r-15"></i>+92-213-453-7211 <br />|or Whatsapp
                    +92-301-8222-487
                  </li>
                  <li>
                    <i aria-hidden="true" class="fa fa-envelope m-r-15"></i>info@bizsoft.pk
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
  <!-- footer end ----------------------------------------------------------------------------- -->
  <!-- ======================================================================================== -->
  <!-- copyright css start -------------------------------------------------------------------- -->
  <div class="app2 copyright">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="center-content">
            <div>
              <h6 class="copyright-text text-white">
                Copyright © 2023, Development by BizSoft Solutions (Pvt)
                Limited. All Rights Reserved.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-tap-to-top></app-tap-to-top>
</div>
<style>
  #navcolour {
    color: rgb(253, 255, 255);
  }
</style>
<!-- copyright end -------------------------------------------------------------------------- -->
<!-- ======================================================================================== -->
<!-- ------------------------------------- The End ------------------------------------------ -->