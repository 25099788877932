import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { transition, trigger, useAnimation } from "@angular/animations";
import { fadeInRight } from "ng-animate";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.prod";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ColorScssService } from "../../shared/service/color-scss.service";
import { blogBasicDB } from "../../shared/data/blog/blog-basic/column";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  bookmark?: boolean;
  children?: Menu[];
}
@Injectable({
  providedIn: "root",
})
@Component({
  selector: "poultry-app",
  templateUrl: "poultry-app.component.html",
  //styleUrls: ['poultry-app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("fadeInRight", [
      transition(
        "* => *",
        useAnimation(fadeInRight, {
          // Set the duration to 5seconds and delay to 0seconds
          params: { timing: 5, delay: 0 },
        })
      ),
    ]),
  ],
})
// Main File Start ////////////////////////////////////////////////////////////////////////
export class PoultryAppComponent implements OnInit {
  public getjsonvalue: any;
  public postjsonvalue: any;
  public myForm: FormGroup;
  public submitted: boolean;
  public blogData: any;
  public work: any = "Poultry Broiler";
  public activeClass = "index";
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private title: Title,
    public colorPicker: ColorScssService,
    @Inject(DOCUMENT) private document: Document,
    private FB: FormBuilder,
    private modalService: NgbModal
  ) {
    this.blogData = blogBasicDB.column;
  }
  ngOnInit() {
    // this.ListingParams = [{ name: 'mfiled', value: 'name' }, { name: 'fvalue', value: 'value' }],
    this.title.setTitle(this.route.snapshot.data["title"]);
    this.colorPicker.setColorScheme("color-2");

    debugger;
    this.myForm = this.FB.group({
      id: [""],
      requestType: ["", [Validators.required]],
      requestByName: ["", [Validators.required]],
      companyName: ["", [Validators.required]],
      mobile: ["", [Validators.required]],
      email: ["", [Validators.required]],
      message: [""],
      sourceurl: [""],
    });
  }

  MENUITEMS: Menu[] = [];
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  active(val) {
    this.activeClass = val;
    document.querySelector(`#${val}`).scrollIntoView({ behavior: "smooth" });
  }
  //  <!-- --------------------------------------------------------------------------------->
  //   Services Start ///////////////////////////////////////////////////////////////////////
  services = [
    {
      img: "assets/images/app_landing2/service/2-1.png",
      title: "FINANCIAL MANAGEMENT",
      description1:
        "Financial Management is a complete accounting module that helps...",
      description2:
        "you maintain transactions on a daily basis and keeps you updated with multiple aspects of advanced reporting.",
    },
    {
      img: "assets/images/app_landing2/service/2-2.png",
      title: "FLOCK MANAGEMENT",
      description1:
        "Flock Management is a module that allows you to create a new active...",
      description2:
        "flock, issuance and consumption of feed, medicine and vaccination. And log of mortality with photo upload. Flock costing and Flock profit or loss dates you from multiple aspects.",
    },
    {
      img: "assets/images/app_landing2/service/2-3.png",
      title: "PURCHASE MANAGEMENT",
      description1:
        "Purchase Management Module that allows you to maintain purchasing...",
      description2: "of chicks, feed, medicine Vaccine for your active flocks.",
    },
    {
      img: "assets/images/app_landing2/service/2-4.png",
      title: "SALES MANAGEMENT",
      description1:
        "Sales Management Module that allows you to set rate of chicken or eggs and...",
      description2:
        "sell them. Sales or transfer of Feed, Medicine and Vaccine with managing of Arties commissions.",
    },
    {
      img: "assets/images/app_landing2/service/2-5.png",
      title: "ACTIVITY/CONSUMPTION",
      description1:
        "Activity / Consumption allows you to check the daily activity of your...",
      description2:
        "chick’s health and weight. Daily basis purchase mortality with photo upload. Assists in stock maintenance along with daily basis prefeed, medicine and vaccination activity.",
    },
    {
      img: "assets/images/app_landing2/service/2-6.png",
      title: "DASHBOARDS & SMART REPORTING",
      description1:
        "Advanced Dashboard allows you to monitor summarized information in ",
      description2:
        "one window include reporting module which exist multiple dynamic reports of detailed ledgers, flock costings, customers aging, trail balances, profit and loss, balance sheets and many more.",
    },
  ];
  //   Services End /////////////////////////////////////////////////////////////////////////
  //  <!-- --------------------------------------------------------------------------------->
  //   Team Start ///////////////////////////////////////////////////////////////////////////
  teams = [
    {
      img: "assets/images/app_landing2/team/1.png",
      name: "BROILER POULTRY",
      designation: "Poultry Broiler Software",
      description: "A Software For Broiler Poultry Business.",
    },
    {
      img: "assets/images/app_landing2/team/2.png",
      name: "LAYER POULTRY",
      designation: "Poultry Layer Software",
      description: " A Software For Layer Poultry Business.",
    },
    {
      img: "assets/images/app_landing2/team/3.png",
      name: "POULTRY MEDICINE",
      designation: "Poultry Medicine Software",
      description: "A Software For Poultry Medicine Business.",
    },
    {
      img: "assets/images/app_landing2/team/4.png",
      name: "POULTRY FEED",
      designation: "Poultry Feed Software",
      description: "A Software For Poultry Feeds Business.",
    },
  ];

  teamcarouselOptions = {
    items: 5,
    margin: 30,
    autoHeight: true,
    nav: false,
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
        margin: 10,
      },
      991: {
        items: 3,
        margin: 10,
      },
    },
  };

  // Team End ///////////////////////////////////////////////////////////////////////////////
  //  <!-- --------------------------------------------------------------------------------->
  // workFlow Start /////////////////////////////////////////////////////////////////////////
  openWorkSlide(val) {
    this.work = val;
  }

  nav = [
    {
      img: "assets/images/app_landing2/team/1.png",
      title: "Poultry Broiler",
    },
    {
      img: "assets/images/app_landing2/team/2.png",
      title: "Poultry Layer",
    },
  ];

  content = [
    {
      img: "assets/images/app_landing2/service/broiler.jpg",
      title: "Poultry Broiler",
    },
    {
      img: "assets/images/app_landing2/service/layer.jpg",
      title: "Poultry Layer",
    },
  ];
  // workFlow End ///////////////////////////////////////////////////////////////////////////
  //  <!-- --------------------------------------------------------------------------------->
  // screenshots Start //////////////////////////////////////////////////////////////////////
  screenshots = [
    { img: "assets/images/app_landing2/screenshot/1.png" },
    { img: "assets/images/app_landing2/screenshot/2.png" },
    { img: "assets/images/app_landing2/screenshot/3.png" },
    { img: "assets/images/app_landing2/screenshot/4.png" },
  ];

  screenshotscarouselOptions = {
    items: 3,
    margin: 55,
    autoHeight: true,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      360: {
        items: 2,
        margin: 10,
      },
      480: {
        margin: 15,
      },
      576: {
        margin: 15,
      },
      1000: {
        items: 3,
      },
    },
  };
  //   screenshots End //////////////////////////////////////////////////////////////////////
  //  <!-- --------------------------------------------------------------------------------->
  //   team-member Start ////////////////////////////////////////////////////////////////////
  users = [
    {
      img: "assets/images/app_landing2/team/5.png",
      name: "Quick Deployment",
      designation: "Successful Validation Must be Done Before Release Day.",
    },
    {
      img: "assets/images/app_landing2/team/6.png",
      name: "Desktop & Web Reporting",
      designation: "Helps to Keep a Variety of Risks at Bay",
    },
    {
      img: "assets/images/app_landing2/team/7.png",
      name: "Interactive Dashboards",
      designation: "Interacting on a Single Screen in Business Necessity.",
    },
    {
      img: "assets/images/app_landing2/team/8.png",
      name: "Backward Forward Traceability",
      designation: "High Traceability Within an Organization.",
    },
  ];
  //   team-member End //////////////////////////////////////////////////////////////////////
  //  <!-- --------------------------------------------------------------------------------->
  //   pricing Start ////////////////////////////////////////////////////////////////////////
  pricing = [
    {
      icon: "rocket-ship",
      package: "Broiler Basic",
      feature1: "10 Active Flocks",
      feature2: "One Company",
      feature3: "One Location",
      feature4: "Posting Voucher",
      feature5: "Advance Reporting",
      price: "Nominal Setup Charges",
      price2: "15,000",
      btn: "purchase Request",
    },
    {
      icon: "rocket-ship",
      package: "Broiler Premium",
      feature1: "Unlimited Active Flocks",
      feature2: "Multi Company",
      feature3: "Multi Location",
      feature4: "Posting Voucher",
      feature5: "Advance Reporting",
      feature6: "SMS Alerts",
      price: "Nominal Setup Charges",
      price2: "25,000",
      btn: "purchase Request",
    },
    {
      icon: "rocket-ship",
      package: "Layer Premium",
      feature1: "Unlimited Active Flocks",
      feature2: "Multi Company",
      feature3: "Multi Location",
      feature4: "Posting Voucher",
      feature5: "Advance Reporting",
      feature6: "SMS Alerts",
      price: "Nominal Setup Charges",
      price2: "25,000",
      btn: "purchase Request",
    },
  ];

  pricingcarouselOptions = {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      576: {
        items: 2,
        margin: 10,
      },
      991: {
        items: 2,
        margin: 15,
      },
      992: {
        items: 3,
        margin: 15,
      },
      1000: {
        items: 3,
      },
    },
  };
  //   pricing End //////////////////////////////////////////////////////////////////////////
  //  <!-- ------------------------------------------------------------------------------ -->
  //   brand Start //////////////////////////////////////////////////////////////////////////
  brands = [
    { img: "assets/images/app_landing2/brand/1.png" },
    { img: "assets/images/app_landing2/brand/2.png" },
    { img: "assets/images/app_landing2/brand/3.png" },
    { img: "assets/images/app_landing2/brand/4.png" },
    { img: "assets/images/app_landing2/brand/5.png" },
  ];

  brandcarouselOptions = {
    items: 6,
    margin: 80,
    autoHeight: true,
    nav: true,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      320: {
        items: 2,
        margin: 10,
      },
      360: {
        items: 3,
        margin: 20,
      },
      480: {
        items: 4,
        margin: 30,
      },
      767: {
        items: 4,
        margin: 30,
      },
      991: {
        items: 5,
        margin: 20,
      },
      992: {
        item: 6,
      },
    },
  };
  //   brand End ////////////////////////////////////////////////////////////////////////////
  //  <!-- ------------------------------------------------------------------------------ -->
  //  function Start ////////////////////////////////////////////////////////////////////////
  fadeInRight: any;
  //  function End //////////////////////////////////////////////////////////////////////////
  //  <!-- ------------------------------------------------------------------------------ -->
  //   demo Start ///////////////////////////////////////////////////////////////////////////
  async submit() {
    debugger;
    let values = this.myForm.value;
    if (!this.myForm.controls["id"].value) {
      this.myForm.controls["id"].setValue(0);
    }
    if (!this.myForm.controls["requestType"].value) {
      this.myForm.controls["requestType"].setValue("Proposal Quotation");
    } else {
      this.myForm.controls["requestType"].setValue("Demo request");
    }
    console.log(values);
    debugger;
    await this.http
      .post(environment.apiURL + "Customer_Request/save", this.myForm.value)
      .toPromise()
      .then((user: any) => {
        debugger;
        if (user.id > 0) {
          this.myForm.controls["id"].setValue(user.id);
          alert(user.messages);
        } else {
        }
      })
      .catch((error) => {
        if (error) {
        }
      });
  }
  public getMathod() {
    this.http.get("https://localhost:44368/api/Customer_Request/save");
  }
  //   demo End /////////////////////////////////////////////////////////////////////////////
  //  <!-- ------------------------------------------------------------------------------ -->

  openVerticallyCentered(content) {
    this.modalService.open(content);
  }
}
