export const environment = {
  production: true,

  // buyUrl : "https://1.envato.market/6NV1b",
  SCARF_ANALYTICS : false,
  // adminRoot: '/APP',
  // errorRoot: '/APP/blank-page',
  // loginURL: '/user/login',
  // live
  apiURL: "https://api-crm.bizsoft.app/api/",
  "api-CRM": "https://api-crm.bizsoft.app/api/",

   //apiURL: "https://api-fmis.bizsoft.app/api/",
 // "api-CRM": "https://localhost:44368/api/",
 // apiUrl:  'https://localhost:44368/api/', 
  // local
 // apiURL: "https://localhost:44368/api/",
 




};
